// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

$primary: #4078c0;
$warning: #fff8c5;
$danger: #bd2c00;
$info: #ddf4ff;
$success: #2da44e;
$font-family-base: "-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"";
$font-size-base: 0.875rem;


$btn-padding-x: 16px;
$btn-padding-y: 5px;
$btn-font-weight: 500;

$font-weight-bold: 600;
@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here